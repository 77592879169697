import request from '@/utils/request'

export function getMenus (params) {
  return request({
    url: 'admin/menus',
    params,
    method: 'get',
    baseURL: process.env.VUE_APP_BASEURL_API
  })
}
export function createMenu (data) {
  return request({
    url: 'admin/menus',
    data,
    method: 'post',
    baseURL: process.env.VUE_APP_BASEURL_API
  })
}
export function updateMenu (id, data) {
  console.log(id, 'id')
  return request({
    url: `admin/menus/${id}`,
    data,
    method: 'put',
    baseURL: process.env.VUE_APP_BASEURL_API
  })
}
