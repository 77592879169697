
// import axios from 'axios'

import request from '@/utils/request'

export const commonRequestPost = (url, data, clientConfig = {}) => {
  return request({ url, method: 'POST', data, baseURL: process.env.VUE_APP_BASEURL_API })
}
export const commonRequestGet = (url, paramData, optionsSource) => {
  let params
  if (paramData && typeof paramData === 'object') {
    params = paramData
  } else {
    params = undefined
  }

  return request({ url, method: 'GET', params, optionsSource, baseURL: process.env.VUE_APP_BASEURL_API })
}
export const commonRequestFormPost = (url, params) => {
  return request({ url, method: 'POST', params, baseURL: process.env.VUE_APP_BASEURL_API })
}
export const commonRequestPut = (url, data) => {
  return request({ url, method: 'PUT', data, baseURL: process.env.VUE_APP_BASEURL_API })
}

export const commonRequestDelete = (url, params) => {
  return request({ url, method: 'DELETE', params, baseURL: process.env.VUE_APP_BASEURL_API })
}

export const commonRequestPatch = (url, params) => {
  return request({ url, method: 'PATCH', params, baseURL: process.env.VUE_APP_BASEURL_API })
}
